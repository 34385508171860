import React from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';
import { connect } from 'react-redux';

import styles from './HelpTab.css';
import { DefaultQuestionsList } from './HelpTab.data';
import { SupportForm } from './SupportForm/SupportForm';
import {HttpUtils} from "../../../../utils/HttpUtils";
import { MiscUtils } from '../../../../utils/MiscUtils';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { InlineDropdown } from '../../../atoms/InlineDropdown/InlineDropdown';
import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import { PageTypes } from '../../../constants/Pages';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { WhatsAppIcon } from '../../../FigmaStyleguide/Icons/WhatsAppIcon';
import { IGame } from '../../../models/Game/Game';
import { HelpTabSupportFormTypes } from '../../../models/HelpFormStatus';
import { SupportCard } from '../../../molecules/Cards/SupportCard/SupportCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { GameService } from '../../../services/GameService';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import { setSideMenuActivePage } from '../../../store/ducks/layout';
import { SuggestionsSection } from '../SuggetstionsSection/SuggestionsSection';

declare global {
    interface Window {
        fcWidget: any;
    }
}

type HelTabProps = {
    isHelpFormActive: boolean;
    supportFormType: HelpTabSupportFormTypes;
    games: IGame[];
    game: IGame;
    pageType: PageTypes;
    currentLang: string;
    dispatch: any;
    extendMediaQuery: any;
};

const SwipeSlider = loadable(() =>
    MiscUtils.loadableRetry(() => import('../../../atoms/SwipeSlider/SwipeSlider'), { retries: 3 })
);
const ARKADIUM_SUPPORT_WHATSAPP_URL = 'https://api.whatsapp.com/send?phone=12123373796';
const ARKADIUM_SUPPORT_CHAT_JS = 'https://fw-cdn.com/11008576/3757536.js';

class HelpTabBase extends React.PureComponent<HelTabProps, any> {
    readonly state = {
        DefaultQuestionsList: DefaultQuestionsList,
        relatedArticles: {},
        formType: this.props.isHelpFormActive
            ? HelpTabSupportFormTypes.SUPPORT_FORM
            : HelpTabSupportFormTypes.QUESTIONS_LIST,
        isChatBtnVisible: false
    };

    componentDidMount() {
        HttpUtils.loadScript(ARKADIUM_SUPPORT_CHAT_JS).then(()=> {
            this.setState({
                isChatBtnVisible: true
            })
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isHelpFormActive && !this.props.isHelpFormActive) {
            this.setState({
                formType: HelpTabSupportFormTypes.QUESTIONS_LIST,
            });
        }

        if (prevProps.supportFormType !== this.props.supportFormType) {
            this.setState({
                formType: this.props.supportFormType,
            });
        }
    }

    onChatBotButtonClick = (): void => {
        if (window.fcWidget) {
            Analytics.trackEvent(Analytics.newHome.chatBotButtonClick('chatBotStart'));
            window.fcWidget.open();
        }

    };

    onWhatsAppBtnClick = () => UrlService.historyPushStateFallback(ARKADIUM_SUPPORT_WHATSAPP_URL);

    onFeedbackClick = () => {
        Analytics.trackEvent(Analytics.newHome.searchNavSupportClick('feedback'));
        this.setState({
            formType: HelpTabSupportFormTypes.SUPPORT_FORM,
        });
    };

    onCancelClick = () => {
        Analytics.trackEvent(Analytics.newHome.searchNavFeedbackClick(`cancel`));
        UserService.closePanel();
    };

    onSuccess = () => {
        this.props.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB_SUCCESS));
    };

    renderQuestionsList() {
        const ArticleLinks = (props: { titleTKey: string; articles: any[]; fallbackArticles?: any[] }) => {
            const { articles, fallbackArticles, titleTKey } = props || {};
            const articleLinks = articles?.length ? articles : fallbackArticles?.length ? fallbackArticles : null;

            return (
                articleLinks && (
                    <>
                        {titleTKey && <I18nText as="h2" keyName={props.titleTKey} className={styles.h2} />}
                        <ul className={styles.ul}>
                            {articleLinks.map((item, index) => (
                                <li key={`HELP_TAB_FAQ_POPULAR-${index}-${item.title}`}>
                                    <Button
                                        useDefaultStyles={false}
                                        noPadding
                                        className={styles.question}
                                        onClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.searchNavSupportClick(`FAQ | ${index}`)
                                            );
                                            window.open(
                                                item.path
                                                    ? `https://support.arkadium.com/en/support/solutions/articles/${item.path}`
                                                    : item.pathFull,
                                                '_blank'
                                            );
                                        }}
                                        data-element-description="FAQ-item-popular"
                                    >
                                        <I18nText keyName={item.title} />
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </>
                )
            );
        };

        return (
            <div className={styles.container}>
                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                    <div style={{ display: 'flex' }}>
                        {this.renderSupportForm()}
                        <div>
                            <I18nText
                                as="h2"
                                keyName="HELP_TAB_FAQ_TITLE"
                                className={classNames(styles.h2, styles.whatsAppText)}
                            />
                            {this.state.isChatBtnVisible && (<Button
                                fullWidth
                                className={styles.chatBotBtn}
                                type="button"
                                id="custom_fc_button"
                                onClick={this.onChatBotButtonClick}
                            >
                                Start Chat
                            </Button>)}
                            <Button
                                fullWidth
                                className={styles.whatsAppBtn}
                                onClick={this.onWhatsAppBtnClick}
                                type="button"
                            >
                                <WhatsAppIcon />
                                Chat on WhatsApp
                            </Button>
                            <ArticleLinks
                                titleTKey="HELP_TAB_FAQ_RECOMMENDED"
                                articles={(this.state.relatedArticles as any)?.recommended}
                            />
                            <ArticleLinks
                                titleTKey="HELP_TAB_FAQ_POPULAR"
                                articles={(this.state.relatedArticles as any)?.popular}
                                fallbackArticles={this.state.DefaultQuestionsList[this.props.currentLang]}
                            />
                        </div>
                    </div>
                </Responsive>
                <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                    <InlineDropdown
                        dropdownContainerClassName={styles.dropdownContainer}
                        iconClassName={styles.dropdownIcon}
                        buttonClassName={styles.dropdownButton}
                        captionClassName={styles.dropdownCaption}
                        listItemClassName={styles.questionMobile}
                        caption="HELP_TAB_FAQ"
                        i18n
                        items={this.state.DefaultQuestionsList[this.props.currentLang]}
                        onItemClick={(item, index) => {
                            Analytics.trackEvent(Analytics.newHome.searchNavSupportClick(`FAQ | ${index}`));
                            window.open(item.pathFull, '_blank');
                        }}
                    />
                    <I18nText
                        as="h2"
                        keyName="HELP_TAB_FAQ_TITLE"
                        className={classNames(styles.h2, styles.whatsAppText)}
                    />
                    {this.state.isChatBtnVisible && (
                        <Button
                            fullWidth
                            className={styles.chatBotBtn}
                            type="button"
                            id="custom_fc_button"
                            onClick={this.onChatBotButtonClick}
                        >
                            Start Chat
                        </Button>
                    )}
                    <Button className={styles.whatsAppBtn} onClick={this.onWhatsAppBtnClick}>
                        <WhatsAppIcon />
                        Chat on WhatsApp
                    </Button>
                </Responsive>
                <Responsive
                    minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}
                    maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}
                >
                    <ul className={styles.supportCards}>
                        <li>
                            <SupportCard
                                image={UrlService.toCDNUrl(
                                    '/illustrations/support/illustrations-support-support-1.png'
                                )}
                                onClick={this.onFeedbackClick}
                                caption="HELP_TAB_FEEDBACK_HEADING"
                                description="HELP_TAB_FEEDBACK_MESSAGE"
                                dataElementDescription="provide-feedback"
                            />
                        </li>
                        <li>
                            <SupportCard
                                image={UrlService.toCDNUrl(
                                    '/illustrations/support/illustrations-support-support-2.png'
                                )}
                                onClick={() => {
                                    Analytics.trackEvent(Analytics.newHome.searchNavSupportClick(`customer portal`));
                                    window.open(
                                        `https://support.arkadium.com/${this.props.currentLang}/support/home`,
                                        '_blank'
                                    );
                                }}
                                caption="HELP_TAB_CUSTOMER_PORTAL_HEADING"
                                description="HELP_TAB_CUSTOMER_PORTAL_MESSAGE"
                            />
                        </li>
                    </ul>
                </Responsive>
                <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}>
                    <div className={styles.mobileInnerContainer}>
                        <SwipeSlider
                            noShadows={false}
                            items={[1, 2]}
                            renderItem={(item, index) => {
                                if (index === 0) {
                                    return (
                                        <SupportCard
                                            image={UrlService.toCDNUrl(
                                                '/illustrations/support/illustrations-support-support-1.png'
                                            )}
                                            onClick={this.onFeedbackClick}
                                            caption="HELP_TAB_FEEDBACK_HEADING"
                                        />
                                    );
                                }

                                return (
                                    <SupportCard
                                        image={UrlService.toCDNUrl(
                                            '/illustrations/support/illustrations-support-support-2.png'
                                        )}
                                        onClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.searchNavSupportClick(`customer portal`)
                                            );
                                            window.open('https://support.arkadium.com/en/support/home', '_blank');
                                        }}
                                        caption="HELP_TAB_CUSTOMER_PORTAL_HEADING"
                                        description="HELP_TAB_CUSTOMER_PORTAL_MESSAGE_SHORTEN"
                                    />
                                );
                            }}
                        />
                    </div>
                </Responsive>
                <div className={styles.mobileInnerContainer}>
                    <SuggestionsSection games={this.props.games} extendMediaQuery={this.props.extendMediaQuery} />
                </div>
            </div>
        );
    }

    renderSupportForm() {
        return (
            <SupportForm
                onSuccess={this.onSuccess}
                onCancel={this.onCancelClick}
                pageType={this.props.pageType}
                game={this.props.game}
                setRelatedArticles={(list) => this.setState({ relatedArticles: list })}
                currentLang={this.props.currentLang}
            />
        );
    }

    render() {
        let element;

        switch (this.state.formType) {
            case HelpTabSupportFormTypes.QUESTIONS_LIST: {
                element = this.renderQuestionsList();
                break;
            }

            case HelpTabSupportFormTypes.SUPPORT_FORM: {
                element = this.renderSupportForm();
                break;
            }
        }

        return (
            <div
                className={classNames({
                    [styles.extendMediaQuery]: this.props.extendMediaQuery,
                    [styles.supportFormHeight]: this.state.formType === HelpTabSupportFormTypes.SUPPORT_FORM,
                })}
            >
                {element}
            </div>
        );
    }
}

export const HelpTab = connect((state) => ({
    isHelpFormActive: state.sideMenu.helpFormStatus.active,
    supportFormType: state.sideMenu.helpFormStatus.supportFormType,
    game: state.gameArkadiumSlug
        ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(state.games), state.gameArkadiumSlug)
        : '',
    pageType: state.pageType,
    currentLang: MiscUtils.fixMissingLang(state.currentLang),
}))(HelpTabBase);
