import './polyfills';
import React, { Suspense } from 'react';

import { loadableReady } from '@loadable/component';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import Cookies from 'js-cookie';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { withSSR } from 'react-i18next';
import { Provider } from 'react-redux';
import './App.css';
import { onCLS, onLCP, onINP, onFCP, onTTFB } from 'web-vitals';

import { AppBodyWrapper } from './client/appbody/AppBodyWrapper';
import { RouterWrapper } from './client/atoms/RouterWrapper/RouterWrapper';
import { environment } from './client/config/environment';
import { Snackbar } from './client/FigmaStyleguide/Snackbar/Snackbar';
import { LS_COOKIE_CONSTS } from './client/models/Enums';
import { WebVitals } from './client/models/WebVitals';
import { ABTEST_COOKIE, ABTestManager } from './client/services/ABTests/ABTestManager';
import { ABTestProvider } from './client/services/ABTests/ABTestReact';
import { Analytics } from './client/services/Analytics/Analytics';
import { LeanplumAnalytics } from './client/services/Analytics/LeanplumAnalytics';
import { AppInsightService } from './client/services/AppInsight';
import { AuthData, AuthDataProvider } from './client/services/AuthDataReact';
import { DisplayAdService } from './client/services/DisplayAdService';
import { GameService } from './client/services/GameService';
import { GoogleAnalyticsService } from './client/services/GoogleAnalyticsService';
import { LocalStorageService } from './client/services/LocalStorage';
import { MediaContextProvider } from './client/services/MediaService';
import ScrollToTopService from './client/services/ScrollToTopService';
import { SearchService } from './client/services/Search';
import { StartupService } from './client/services/StartupService';
import { UrlService } from './client/services/UrlService';
import { reduxStore } from './client/store';
import { i18nClientInit } from './i18n';


declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: false; // removes the `sm` breakpoint
        md: true;
        lg: false; // removes the `lg` breakpoint
        xl: true;
    }
}

const { store, history } = reduxStore;

(window as any).STORE = store;
LocalStorageService.setItem(LS_COOKIE_CONSTS.ADO_BUILD_ID, environment.ADO_BUILD_ID);

i18nClientInit();

const { texts, currentLang } = store.getState();

UrlService.currentLang = currentLang;

(Provider as any).displayName = 'Provider';

SearchService.initFuse(GameService.gameModelToGame(store.getState().games));

export class App extends React.Component {
    static displayName = 'App';
    protected abtests: ABTestManager;
    protected theme;

    state = {
        authData: {
            email: '',
        } as AuthData,
        callbackId: null,
    };

    constructor(props) {
        super(props);
        const state = store.getState();
        const cookie = Cookies.get(ABTEST_COOKIE);

        this.abtests = new ABTestManager();
        this.abtests.init(state.abTests, cookie && cookie);

        this.theme = createTheme({
            components: {
                MuiGrid: {
                    styleOverrides: {
                        // every grid container will have these styles
                        container: {
                            padding: '0 1rem',
                        },
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    md: 600,
                    xl: 1025, // todo check 1025
                },
            },
            typography: {
                fontFamily: 'inherit',
            },
        });
    }

    initLibs = () => {
        // restore lock viewport scroll ability
        document.body.style.overflow = 'initial';
        document.body.style.position = 'initial';
        // for games team set global variable
        (window as any).arenaName = environment.ARENA_DOMAIN;
        StartupService.init();
        DisplayAdService.start();
        GoogleAnalyticsService.init();
        AppInsightService.init();
        LeanplumAnalytics.init();
    };


    trackWebVitals() {
        onLCP(metric => addToMetrics({...metric, name: metric.name}));

        onCLS(metric => {
            Analytics.trackEvent(Analytics.general.webVitals({[metric.name]: {value: metric.value, name: metric.name}}));
        });

        onINP(metric => {
            Analytics.trackEvent(Analytics.general.webVitals({[metric.name]: {value: metric.value, name: metric.name}}));
        });

        onFCP(metric => addToMetrics({...metric, name: metric.name}));

        onTTFB(metric => addToMetrics({...metric, name: metric.name}));

        let allMetrics: WebVitals = {};

        function addToMetrics(metric) {
            allMetrics[metric.name] = {
                value: metric.value
            };

            // collect on page load: FCP, TTFB, LCP
            if (allMetrics['LCP'] && allMetrics['FCP'] && allMetrics['TTFB']) {
                sendToAnalytics(allMetrics);
            }
        }

        function sendToAnalytics(metrics: WebVitals) {

            Analytics.trackEvent(Analytics.general.webVitals(metrics));

            allMetrics = {};
        }

    }

    componentDidMount() {
        this.initLibs();
        this.trackWebVitals();
    }

    componentWillUnmount() {

    }

    setAuthData = (authData) => {
        this.setState((prevState) => ({ ...prevState, authData }));
    };

    render() {
        const toRender = (
            <ABTestProvider manager={this.abtests}>
                <HelmetProvider>
                    <AuthDataProvider value={this.state.authData} setAuthData={this.setAuthData}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <RouterWrapper>
                                    <MediaContextProvider>
                                        <ThemeProvider theme={this.theme}>
                                            <AppBodyWrapper />
                                            <ScrollToTopService />
                                            <Snackbar />
                                        </ThemeProvider>
                                    </MediaContextProvider>
                                </RouterWrapper>
                            </ConnectedRouter>
                        </Provider>
                    </AuthDataProvider>
                </HelmetProvider>
            </ABTestProvider>
        );

        return toRender;
    }
}

const ExtendedApp = withSSR()(App);
const root = document.getElementById('root');
const renderMethod = root && root.innerHTML !== '' ? hydrate : render;

loadableReady(() => {
    renderMethod(
        <ExtendedApp initialLanguage={currentLang} initialI18nStore={{ [currentLang]: { translation: texts } }} />,
        root
    );
});
export default App;
