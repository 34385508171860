import 'cross-fetch/polyfill';

export class HttpUtils {
    public static loadScript(url, token?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script: any = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;

            if (token) {
                script['data-ad-client'] = token;
            }

            if (script.readyState) {
                // IE
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {
                // Others
                script.onload = resolve;
                script.onerror = reject;
            }

            script.src = url;
            document.head.appendChild(script);
        });
    }

    public static async fetch(url, options?, jsonResponse = true, returnNullContent = false): Promise<any> {
        options = options || {};
        options.headers = options.headers || new Headers();
        // options.headers.set('Content-type', 'application/json');

        const response = await fetch(url, options);

        HttpUtils.assertFetchStatus(response);

        if (returnNullContent) {
            if (response.status === 204 || !(response.ok || response.status === 304)) {
                return null;
            }
        }

        return jsonResponse ? response.json() : response.text();
    }

    // special fetch function for blog posts with fallback url
    public static async fetchBlogPost(url, fallbackUrl, options?, jsonResponse = true, returnNullContent = false): Promise<any> {
        options = options || {};
        options.headers = options.headers || new Headers();
        
        try {
            let response = await fetch(url, options);

            if (response.status === 404) {
                response = await fetch(fallbackUrl, options);
            }

            HttpUtils.assertFetchStatus(response);

            if (returnNullContent) {
                if (response.status === 204 || !(response.ok || response.status === 304)) {
                    return null;
                }
            }

            return jsonResponse ? response.json() : response.text();
        } catch (error) {
            console.error('Fetch of blog post failed:', error);
            throw error;
        }


    }



    private static assertFetchStatus(res): void {
        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(`Request failed: ${res.status} - ${res.statusText}`);
        }
    }
}
