import {
    AnalyticsEventAction,
    AnalyticsEventBuilder,
    AnalyticsEventsPair,
    makeEventsPair,
} from './AnalyticsEventBuilder';
import { BlogAnalytics } from './BlogAnalytics';
import { CategoriesAnalytics } from './CategoriesAnalytics';
import { AnalyticsExtension, extendersList } from './Extensions';
import { GamesAnalytics } from './GamesAnalytics';
import { GemsAnalytics } from './GemsAnalytics';
import { GeneralAnalytics } from './GeneralAnalytics';
import { GiftCardAnalytics } from './GiftCardAnalytics';
import { shouldDoUpdate } from './LeanplumAnalytics';
import { NewHomeAnalytics } from './NewHomeAnalytics';
import { PageBuilderAdvantageAnalytics } from './PageBuilderAdvantageAnalytics';
import { ProfileAnalytics } from './ProfileAnalytics';
import { SubscriptionAnalytics } from './SubscriptionAnalytics';
import { devConsole } from '../../../utils/DevConsole';
import { DeviceUtils } from '../../../utils/DeviceUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment';
import { getAvatarAnalyticsKey } from '../../constants/AvatarImagesData';
import { EnvironmentName } from '../../constants/Environment';
import { FavoritesAnalytics } from '../../features/Favorites/FavoritesAnalytics';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { GameEvents } from '../../models/Game/GameObservable';
import { RegistrationSource } from '../../models/User/RegistrationSource';
import { MobileNavMenuUxRedesignedAnalytics } from '../../organisms/MobileNavigationUxRedesigned/MobileNavUxRedesignedAnalytics';
import { reduxStore } from '../../store';
import { GemsAnalyticsRegistrationLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { AppInsightService } from '../AppInsight';
import { CookieService } from '../CookieService';
import { DeviceDetector } from '../DeviceDetector';
import { GameService } from '../GameService';
import { GoogleAnalyticsService } from '../GoogleAnalyticsService';
import { LocalStorageService } from '../LocalStorage';
import { StartupService } from '../StartupService';

const { store } = reduxStore;

enum LS_AI_PROPS {
    LAST_TRACKED = 'AppInsightsLastTrackedEvent',
}

class AnalyticsService {
    general = new GeneralAnalytics();

    newHome = new NewHomeAnalytics();

    games = new GamesAnalytics();

    categories = new CategoriesAnalytics();

    profile = new ProfileAnalytics();

    subscription = new SubscriptionAnalytics();

    giftCard = new GiftCardAnalytics();

    gems = new GemsAnalytics();

    favorites = new FavoritesAnalytics();

    mobileNavMenuUxRedesigned = new MobileNavMenuUxRedesignedAnalytics();

    globalAIData: any = {
        targetingConsent: LocalStorageService.getItem(LS_COOKIE_CONSTS.TARGETING_CONSENT),
        environment: EnvironmentName[environment.Name]?.toLowerCase(),
    };

    pageBuilderAdvantageAnalytics = new PageBuilderAdvantageAnalytics();

    blogAnalytics = new BlogAnalytics();

    private extensionManager: AnalyticsExtension = new AnalyticsExtension(extendersList);

    async trackEvent(eventPair: Promise<AnalyticsEventsPair>, forceUpdate: boolean = true) {
        if (!MiscUtils.isServer) {
            const event = await eventPair;
            this.extensionManager.extend(event);
            if (event.ai) {
                const ev = event.ai.name;
                const payload = {
                    ...event.ai.data,
                    ...this.globalAIData,
                };
                if (shouldDoUpdate({ [ev]: { ...payload } }, LS_AI_PROPS.LAST_TRACKED) || forceUpdate) {
                    devConsole('AI - trackEvent', ev, payload);
                    AppInsightService.trackEvent(ev, { ...payload });
                }
            }
            if (event.ga) {
                GoogleAnalyticsService.trackEvent(event.ga.data);
            }
        }
    }

    getPlayAgainStart = function (): string {
        if (window.location.href.indexOf('playAgain') !== -1) {
            return AnalyticsEventAction.YES;
        }
        return AnalyticsEventAction.NO;
    };

    getRecPreviousGame = function (): string {
        const param = StartupService.getParameterByName('recPreviousGame', window.location.href);
        if (param) {
            return param;
        }
        return '';
    };

    //
    // Get bookmark type desktop,android,ios
    //
    getBookmarkType = function () {
        if (!MiscUtils.isServer) {
            this.deviceDetector = new DeviceDetector();

            if (sessionStorage.getItem('arkBookmarkType')) {
                return sessionStorage.getItem('arkBookmarkType');
            }

            if (
                window.location.href.indexOf(`${QUERY_STRING_CONSTS.UTM_SOURCE}=desktopIcon`) !== -1 ||
                (DeviceUtils.isDesktopDevice() &&
                    (window.matchMedia('(display-mode: standalone)').matches ||
                        (window as any).navigator.standalone === true))
            ) {
                return 'desktop';
            }

            if (
                (window as any).navigator.standalone === true &&
                this.deviceDetector.DetectBrowser().name === 'Safari'
            ) {
                return 'ios';
            }

            if (window.matchMedia('(display-mode: standalone)').matches) {
                if (this.deviceDetector.DetectBrowser().name === 'Safari') {
                    return 'ios';
                }

                return 'android';
            }

            return '';
        }
    };

    generateLabel = function (domain: string, name: string, game?: string): string {
        if (game) {
            return `${domain} | ${name}/${game}`;
        }
        return `${domain} | ${name}`;
    };

    generateLabelForGA4 = function (domain: string, url: string): string {
        return `${domain} | ${url}`;
    };

    gamePlayNumTrack = function (game) {
        let gamePlayNum: number = parseInt(LocalStorageService.getItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM), 10);
        if (gamePlayNum > 0) {
            gamePlayNum++;
            LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM, gamePlayNum);
            CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_OVERALL_GAMES_PLAYED, gamePlayNum);
        } else {
            gamePlayNum = 1;
            LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM, gamePlayNum);
            CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_OVERALL_GAMES_PLAYED, gamePlayNum);
        }

        CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_LAST_GAME_PLAYED, game.slug);

        if (gamePlayNum > 4 && gamePlayNum <= 100) {
            if (gamePlayNum % 5 === 0) {
                Analytics.trackEvent(Analytics.games.numberTimesPlay(game, `${gamePlayNum}th Gameplay`));
            }
        }
    };

    gamePlayVisitTrack = function (game) {
        const gamePlayLastVisitTime: number = parseInt(
            LocalStorageService.getItem(LS_COOKIE_CONSTS.LAST_TIME_GAME_PLAYED),
            10
        );

        const currentTime = Math.round(new Date().getTime() / 1000);
        if ((currentTime - gamePlayLastVisitTime) / 60 / 60 / 24 > 1) {
            // since last time passed more than 1 day, count new visit
            let gamePlayVisit: number =
                parseInt(LocalStorageService.getItem(LS_COOKIE_CONSTS.GAME_PLAY_VISIT), 10) || 0;
            gamePlayVisit++;

            if (gamePlayVisit > 1 && gamePlayVisit <= 25) {
                if (gamePlayVisit % 5 === 0 || gamePlayVisit === 2) {
                    Analytics.trackEvent(Analytics.games.numberPlayVisits(game, `${gamePlayVisit}th visit`));
                }
            }
            LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_VISIT, gamePlayVisit);
        }
        LocalStorageService.setItem(LS_COOKIE_CONSTS.LAST_TIME_GAME_PLAYED, currentTime);
    };
}

export const Analytics = new AnalyticsService();

export async function trackGameEvent(eventName: string, payload: any, game: IGame) {
    // #137921 AI and GA for game events
    if (!eventName || !payload) {
        return;
    }
    try {
        // generate Game events pair
        const { ai, ga } = payload || {};
        devConsole(`Games AI and GA event (${eventName}) data passed from game: `, { ai, ga });
        const aiData = ai && typeof ai === 'object' && ai !== null && !Array.isArray(ai);
        const gaData = ga && typeof ga === 'object' && ga !== null && !Array.isArray(ga);
        if (!aiData && !gaData) {
            return;
        }
        let eventGa;
        let eventAi;
        // generate GA event
        if (gaData) {
            eventGa = await new AnalyticsEventBuilder();
            eventGa.setName(eventName);
            eventGa.setEventGaCategory(eventName);
            const { eventCategory, eventAction, eventLabel } = ga?.customDimensions || ga || {};
            eventCategory && eventCategory.setEventGaCategory(eventName);
            eventAction && eventGa.setEventGaAction(eventAction);
            eventLabel && eventGa.setEventGaLabel(eventLabel);
        }
        // generate AI event
        if (aiData) {
            eventAi = await new AnalyticsEventBuilder().baseEvent();
            eventAi.setName(eventName);
            eventAi.setCustom('game_event_test', 'ai track from game');
            if (
                eventName.toLowerCase() === 'gameend' ||
                eventName.toLowerCase() === 'round' ||
                eventName.toLowerCase() === 'round_end'
            ) {
                const gameAnalyticsSlug = this.props?.game?.analyticsSlug || this.props?.game?.arkadiumSlug;
                const sdkInfo = await MiscUtils.getSdKInfo();
                eventAi.setCustom('game', gameAnalyticsSlug);
                eventAi.setGame(game, sdkInfo);
            }
            const { customDimensions } = ai;
            if (customDimensions) {
                Object.keys(customDimensions).forEach((key) => {
                    eventAi.setCustom(key, customDimensions[key]);
                });
            }
            // fallback GA event data from AI data
            if (!gaData) {
                const cdNames = Object.keys(customDimensions);
                const cdAdditional = cdNames.filter((cdName) => cdName !== 'action');
                const eventAction = customDimensions?.action;
                const eventLabel = cdAdditional.length ? customDimensions[cdAdditional[0]] : null;
                eventGa = await new AnalyticsEventBuilder();
                eventGa.setName(eventName);
                eventGa.setEventGaCategory(eventName);
                eventAction && eventGa.setEventGaAction(customDimensions.action);
                eventLabel && eventGa.setEventGaLabel(eventLabel);
            }
        }
        // track GA and AI events pair
        const aiEvent = eventAi ? eventAi?.build?.() : null;
        const gaEvent = eventGa ? eventGa?.build?.() : null;
        // send at least 1 event provided
        if (gaEvent || aiEvent) {
            const eventPair = makeEventsPair(gaEvent, aiEvent).then(async (evPair) => {
                devConsole(`Games AI and GA event (${eventName}) track passed and fired: `, await evPair);
                return evPair;
            });
            Analytics.trackEvent(eventPair);
        }
    } catch (err) {
        console.warn(
            `Game events tracking error (could not send ${GameEvents.ANALYTICS_EVENT} to AppInsights / GA):\n`,
            err
        );
    }
}
