import { AnalyticsEventBuilder } from './Analytics/AnalyticsEventBuilder';
import { UrlService } from './UrlService';
import UserService from './UserService';
import { MiscUtils } from '../../utils/MiscUtils';

export class AppInsightService {
    private static customDimensionsAppinsights: {};
    static init() {
        this.trackPageView();
    }

    static trackAppError(error, info = {}, isErrorBoundary = false) {
        const problemPagePath = window ? window.location.pathname : undefined;

        this.trackEvent('js-error', {
            error,
            info: JSON.stringify(info),
            page: problemPagePath,
            adBlockEnabled: MiscUtils.isAdBlockEnabled(),
            isUserLoggedIn: UserService.isUserLoggedIn(),
            isUserSubscribed: UserService.isUserSubscriber(),
            type: isErrorBoundary ? 'Something went wrong' : 'common',
            ...this.customDimensionsAppinsights,
        });
    }

    static async trackPageView() {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackPageView({
                name: document.title,
                uri: UrlService.convertEntities(window.location.href),
                properties: { ...(await new AnalyticsEventBuilder().pageViewEvent()).options, pagePath: window.location.pathname },
                screenResolution: window.innerWidth + 'x' + window.innerHeight,
                ...this.customDimensionsAppinsights,
            });
        }
    }

    static trackEvent(name: string, data: any) {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackEvent({
                name,
                properties: { ...data, pagePath: window.location.pathname },
            });
        }
    }

    public static setCustomDimensions(customDimensions) {
        this.customDimensionsAppinsights = {
            ...this.customDimensionsAppinsights,
            ...customDimensions,
        };
    }
}
